// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
    min-height: 400px;
    display: flex;
    flex-direction: column;
}

.table-title {
    margin-bottom: 12px;
    margin-left: 12px;
}

.soc-cell {
    display: flex;
    align-items: center;
    justify-content: end;
    flex-direction: column;
}

.soc-cell-timeline {
    display: flex;
    align-items: center;
    justify-content: end;
}

.soc-value {
    margin-right: 5px;
}

.fixed-column-height {
    height: 608px;
}

.card-height {
    height: 210px;
}

.metric-text {
    font-size: 15px;
}

.usage-history-chart {
    width: 100%;
}

.history-segement {
    width: 100%;
    display: flex;
    margin-right: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Projects/ProjectDetails.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,oBAAoB;IACpB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;AACrB","sourcesContent":[".wrapper {\n    min-height: 400px;\n    display: flex;\n    flex-direction: column;\n}\n\n.table-title {\n    margin-bottom: 12px;\n    margin-left: 12px;\n}\n\n.soc-cell {\n    display: flex;\n    align-items: center;\n    justify-content: end;\n    flex-direction: column;\n}\n\n.soc-cell-timeline {\n    display: flex;\n    align-items: center;\n    justify-content: end;\n}\n\n.soc-value {\n    margin-right: 5px;\n}\n\n.fixed-column-height {\n    height: 608px;\n}\n\n.card-height {\n    height: 210px;\n}\n\n.metric-text {\n    font-size: 15px;\n}\n\n.usage-history-chart {\n    width: 100%;\n}\n\n.history-segement {\n    width: 100%;\n    display: flex;\n    margin-right: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.network-blue {
    background-color: #06f;
}

.network-green {
    background-color: #1ac69e;
}

.network-orange {
    background-color: #fb5a00;
}

.network-grey {
    background-color: #a3a3a3;
}

.network-pink {
    background-color: #f739e7;
}

.network-diagram-legend-container {
    z-index: 1;
}

.network-transit {
    height: 800px;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    padding-left: 40px;
    padding-right: 40px;
    top: 100px;
    left: 620px;
    border-left: 3px;
    border-right: 3px;
    border-left-style: ridge;
    border-right-style: ridge;
}
`, "",{"version":3,"sources":["webpack://./src/components/NetworkDiagram/NetworkDiagram.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,uBAAkB;IAAlB,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;IACnB,UAAU;IACV,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,wBAAwB;IACxB,yBAAyB;AAC7B","sourcesContent":[".network-blue {\n    background-color: #06f;\n}\n\n.network-green {\n    background-color: #1ac69e;\n}\n\n.network-orange {\n    background-color: #fb5a00;\n}\n\n.network-grey {\n    background-color: #a3a3a3;\n}\n\n.network-pink {\n    background-color: #f739e7;\n}\n\n.network-diagram-legend-container {\n    z-index: 1;\n}\n\n.network-transit {\n    height: 800px;\n    width: fit-content;\n    position: absolute;\n    padding-left: 40px;\n    padding-right: 40px;\n    top: 100px;\n    left: 620px;\n    border-left: 3px;\n    border-right: 3px;\n    border-left-style: ridge;\n    border-right-style: ridge;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

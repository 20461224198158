import { format } from "date-fns";
export const usageHistoryBarChartData = {
    autosize: true,
    config: { displayModeBar: false, responsive: true, staticPlot: false },
    data: [
        {
            hoverinfo: "none",
            hovertemplate: "%{text} kWh",
            marker: { color: "rgba(0,0,255)" },
            name: "", //Has to be empty to not show the 'trace XY' after the value in tooltip
            text: ["1201.00", "1363.34", "936.23", "862.56", "1059.43", "1111.43", "1000.23"],
            textfont: { size: "8" },
            textposition: "top",
            texttemplate: "%{text}<br>kWh",
            type: "bar",
            x: ["04/13", "04/14", "04/15", "04/16", "04/17", "04/18", "04/19"],
            y: ["1201", "1363", "936", "862", "1059", "1111", "1000"]
        }
    ],
    layout: {
        barcornerradius: 3,
        hoverlabel: { font: { size: "16" } },
        margin: {
            b: 30,
            l: 0,
            pad: 0,
            r: 0,
            t: 0
        },
        position: "relative",
        responsive: true,
        showlegend: false,
        textangle: 0,
        xaxis: {
            automargin: true,
            visible: true
        },
        yaxis: {
            automargin: true,
            visible: false
        }
    },
    sliders: [{ pad: { r: 0 } }],
    style: {
        display: "inline-block",
        height: "100%",
        position: "relative",
        width: "100%"
    }
};
export const getBarChartData = (data, filter) => {
    const barChartDate = [];
    const barChartValue = [];
    const getFontSize = (barCount) => {
        const FOURTEEN = 14;
        const TWELVE = 12;
        const TEN = 10;
        const SIX = 6;
        const FOUR = 4;
        let output = 13;
        if (barCount >= FOUR)
            output = FOURTEEN;
        if (barCount >= SIX)
            output = TWELVE;
        if (barCount >= TEN)
            output = TEN;
        return output.toString();
    };
    if (filter === "Weekly") {
        const startIndex = 5;
        const endIndex = 10;
        data?.map(({ startDate, value }) => {
            barChartDate.push(startDate?.substring(startIndex, endIndex).replace("-", "/"));
            barChartValue.push(Math.round(value).toString());
        });
    }
    else if (filter === "Daily") {
        const startIndex = 5;
        const endIndex = 10;
        data?.map(({ date, value }) => {
            barChartDate.push(date?.substring(startIndex, endIndex).replace("-", "/"));
            barChartValue.push(Math.round(value).toString());
        });
    }
    else {
        data?.map(({ endDate, value }) => {
            if (endDate !== undefined) {
                barChartDate.push(format(endDate, "MMM"));
                barChartValue.push(Math.round(value).toString());
            }
        });
    }
    return [
        {
            hoverinfo: "none",
            hovertemplate: "%{text}<br> kWh",
            marker: { color: "rgba(0,0,255)" },
            name: "", //Has to be empty to not show the 'trace XY' after the value in tooltip
            text: barChartValue,
            textangle: 0,
            textfont: { size: getFontSize(barChartValue?.length) },
            textposition: "top",
            texttemplate: "%{text}<br>kWh",
            type: "bar",
            x: barChartDate,
            y: barChartValue
        }
    ];
};

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Handle, Position } from "@xyflow/react";
import { CatHeading } from "blocks-react/bedrock/components/Heading/Heading";
import { CatIconArrowBackAlt } from "blocks-react/bedrock/components/Icons/ArrowBackAlt/ArrowBackAlt";
import { CatIconArrowForwardAlt } from "blocks-react/bedrock/components/Icons/ArrowForwardAlt/ArrowForwardAlt";
import { CatIconBatteryFull } from "blocks-react/bedrock/components/Icons/BatteryFull/BatteryFull";
import { CatIconBox } from "blocks-react/bedrock/components/Icons/Box/Box";
import { CatIconMachineExcavator } from "blocks-react/bedrock/components/Icons/MachineExcavator/MachineExcavator";
import { CatIconMachineTractor } from "blocks-react/bedrock/components/Icons/MachineTractor/MachineTractor";
import { CatIconPower } from "blocks-react/bedrock/components/Icons/Power/Power";
import { CatIconWarningTriangleFilled } from "blocks-react/bedrock/components/Icons/WarningTriangleFilled/WarningTriangleFilled";
import { useState } from "react";
import { AssetTransitDirection } from "../../pages/Projects/ProjectDetails.helper";
import { NetworkDiagramTooltip } from "../NetworkDiagramTooltip/NetworkDiagramTooltip";
import { NodeIconType } from "./CustomNodeEnums";
import NodeToolbarWrapper from "./NodeToolBarWrapper";
export const AssetNode = (props) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [tooltipDelayHandler, setTooltipDelayHandler] = useState(null);
    const handleMouseEnter = () => {
        clearTimeout(tooltipDelayHandler);
        setIsTooltipVisible(true);
    };
    const handleMouseLeave = () => {
        setTooltipDelayHandler(setTimeout(() => setIsTooltipVisible(false), 100));
    };
    const handleClick = (event) => {
        if (event?.target?.offsetParent?.ariaLabel !== null) {
            event.preventDefault();
            event.stopPropagation();
        }
    };
    let icon;
    switch (props.data.iconType) {
        case NodeIconType.EXCAVATOR:
            icon = _jsx(CatIconMachineExcavator, { className: "items-center justify-center flex", size: "xl" });
            break;
        case NodeIconType.TRACTOR:
            icon = _jsx(CatIconMachineTractor, { className: "items-center justify-center flex", size: "xl" });
            break;
        case NodeIconType.ESS:
            icon = _jsx(CatIconBox, { className: "items-center justify-center flex", size: "xl" });
            break;
        case NodeIconType.BATTERY:
            icon = _jsx(CatIconBatteryFull, { className: "items-center justify-center flex", size: "xl" });
            break;
        case NodeIconType.POWER:
            icon = _jsx(CatIconPower, { className: "items-center justify-center flex", size: "xl" });
            break;
        case NodeIconType.WARNING:
        default:
            icon = (_jsx(CatIconWarningTriangleFilled, { className: "items-center justify-center flex text-grey-700 fill-current", size: "xl" }));
            break;
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", { onClick: handleClick, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, children: [_jsx(NodeToolbarWrapper, { align: "start", isVisible: isTooltipVisible, offset: 5, position: Position.Right, children: _jsx(NetworkDiagramTooltip, { ...props.data.toolTip }) }), _jsxs("div", { className: `border-solid border-2 rounded border-gray-800 w-20 h-24 text-center content-center text-black box-border ${props?.data?.selected ? "network-grey" : "bg-white"}`, children: [_jsx("div", { className: `w-3 h-3 rounded-full absolute top-1 right-1 ${props.data.iconColor}` }), _jsx(CatHeading, { variant: "title", children: props.data.label }), icon, props.data?.assetTransitDirection !== undefined &&
                                props.data?.assetTransitDirection.toUpperCase() === AssetTransitDirection.TOWARDS_SOURCE &&
                                props.data?.activity.toUpperCase() === "TRANSPORTING" && (_jsx(CatIconArrowBackAlt, { className: "items-center justify-center flex", size: "xl" })), props.data?.assetTransitDirection !== undefined &&
                                props.data?.assetTransitDirection.toUpperCase() === AssetTransitDirection.TOWARDS_SITE &&
                                props.data?.activity.toUpperCase() === "TRANSPORTING" && (_jsx(CatIconArrowForwardAlt, { className: "items-center justify-center flex", size: "xl" }))] })] }), props.data.hasTarget ? _jsx(Handle, { isConnectable: false, position: Position.Left, type: "target" }) : null, props.data.hasSource ? _jsx(Handle, { isConnectable: false, position: Position.Right, type: "source" }) : null] }));
};

import { jsx as _jsx } from "react/jsx-runtime";
import Plot from "react-plotly.js";
import "./SankeyDiagram.css";
export const SankeyDiagram = ({ customData, labels, links, positions }) => {
    const formatTooltipValues = (value) => {
        if (value < 1)
            return "No Data available";
        return `${Math.round(value)} kWh`;
    };
    const getIncomingSummary = (index) => {
        let incoming = 0;
        links.value.forEach((value, valueIndex) => {
            if (links.target[valueIndex] !== index) {
                return "No Data available";
            }
            incoming = incoming + value;
        });
        return formatTooltipValues(incoming);
    };
    const getOutgoingSummary = (index) => {
        let outgoing = 0;
        links.value.forEach((value, valueIndex) => {
            if (links.source[valueIndex] !== index)
                return "No Data available";
            outgoing = outgoing + value;
        });
        return formatTooltipValues(outgoing);
    };
    const modifiedCustomData = customData.map((custom, index) => {
        return {
            id: custom,
            income: getIncomingSummary(index),
            outcome: getOutgoingSummary(index)
        };
    });
    const data = [
        {
            arrangement: "snap",
            link: {
                ...links,
                hovertemplate: "<extra></extra>"
            },
            node: {
                customdata: modifiedCustomData,
                hovertemplate: "ID: %{customdata.id}<br />Charge Energy: %{customdata.income}<br />Discharge Energy: %{customdata.outcome}<extra></extra>",
                label: labels,
                x: positions.x,
                y: positions.y
            },
            type: "sankey"
        }
    ];
    const layout = {
        autoresize: true,
        font: {
            size: 14
        }
    };
    const config = {
        displayModeBar: false,
        responsive: true
    };
    return _jsx(Plot, { className: "sankey-component", config: config, data: data, layout: layout });
};

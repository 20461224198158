export var MetricsEnergyEnum;
(function (MetricsEnergyEnum) {
    MetricsEnergyEnum["essEnergy"] = "Energy to ESS";
    MetricsEnergyEnum["machineEnergy"] = "Machine energy use";
    MetricsEnergyEnum["residualEnergy"] = "Residual energy balance";
})(MetricsEnergyEnum || (MetricsEnergyEnum = {}));
export var MetricsEnergyDurationEnum;
(function (MetricsEnergyDurationEnum) {
    MetricsEnergyDurationEnum["lastDay"] = "Last day";
    MetricsEnergyDurationEnum["lastMonth"] = "Last 30 days";
    MetricsEnergyDurationEnum["lastWeek"] = "Last 7 days";
})(MetricsEnergyDurationEnum || (MetricsEnergyDurationEnum = {}));
export const MetricsData = [
    {
        data: [
            {
                icon: "down",
                id: 1,
                percentage: "6.9%",
                status: "red",
                value: ""
            },
            {
                icon: "up",
                id: 2,
                percentage: "1.25%",
                status: "green",
                value: ""
            },
            {
                icon: "up",
                id: 3,
                percentage: "82.5%",
                status: "red",
                value: ""
            }
        ],
        id: 1,
        kwh: ["1,162.4 kWh", "3,249.9 kWh", "21,600 kWh"],
        metric: "971.3 kWh",
        name: "Energy to ESS"
    },
    {
        data: [
            {
                icon: "up",
                id: 1,
                percentage: "52.8%",
                status: "red",
                value: ""
            },
            {
                icon: "down",
                id: 2,
                percentage: "-5.1%",
                status: "green",
                value: ""
            },
            {
                icon: "up",
                id: 3,
                percentage: "10.1%",
                status: "red",
                value: ""
            }
        ],
        id: 2,
        kwh: ["755 kWh", "5,105.4 kWh", "20,000 kWh"],
        metric: "1,009.3 kWh",
        name: "Machine energy use"
    },
    {
        data: [
            {
                icon: "up",
                id: 1,
                percentage: "45.9%",
                status: "red",
                value: "1.7"
            },
            {
                icon: "down",
                id: 2,
                percentage: "-40.9%",
                status: "green",
                value: "10.9"
            },
            {
                icon: "up",
                id: 3,
                percentage: "82.5%",
                status: "red",
                value: "17.9"
            }
        ],
        id: 3,
        kwh: ["253.7 kWh", "974.8 kWh", "20, 000 kWh"],
        metric: "416.38 kWh",
        name: "Residual Energy balance"
    }
];

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected .cat-c-table-row {
    background: #d0d0e0;
}

.asset-portfolio-table-row::part(native) .cat-c-table-row {
    background: #d0d0e0;
}
`, "",{"version":3,"sources":["webpack://./src/components/AssetPortfolio/AssetPortfolio.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".selected .cat-c-table-row {\n    background: #d0d0e0;\n}\n\n.asset-portfolio-table-row::part(native) .cat-c-table-row {\n    background: #d0d0e0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as d3 from "d3";
import { useEffect, useRef, useState } from "react";
const Linechart = ({ height, socTrendData, width }) => {
    // const [data] = useState([80, 28, 30, 30, 30, 20, 10, 60, 100, 77]);
    const [data] = useState(socTrendData);
    const svgRef = useRef();
    const max_domain = 100;
    const min_value = 0;
    const y_rect_value = 0;
    const x_rect1_value = 0;
    const x_rect2_value = 46;
    const x_rect3_value = 91;
    const x_rect4_value = 136;
    const margin = { top: 0 };
    useEffect(() => {
        drawLineChart();
    }, [data]);
    // Add logic to draw the chart here
    function drawLineChart() {
        // Setting up svg
        const svg = d3
            .select(svgRef.current)
            .attr("width", width)
            .attr("height", height)
            .style("background", "white")
            .style("margin-top", margin.top);
        // Setting up scaling
        const xScale = d3
            .scaleLinear()
            .domain([0, data.length - 1])
            .range([0, width]);
        const yScale = d3.scaleLinear().domain([min_value, max_domain]).range([height, min_value]);
        const generatedScaledLine = d3
            .line()
            .x((data, index) => xScale(index))
            .y(yScale)
            .curve(d3.curveCardinal);
        // Setting up axes
        const xAxis = d3.axisBottom(xScale);
        const yAxis = d3.axisLeft(yScale);
        svg.append("g").call(xAxis).attr("stroke-width", "3px").attr("transform", `translate(0, ${height})`);
        svg.append("g").attr("stroke-width", "3px").call(yAxis);
        // Setting up data for svg
        svg.selectAll(".line")
            .data([data])
            .join("path")
            .attr("d", (data) => generatedScaledLine(data))
            .attr("fill", "none")
            .attr("stroke-width", "2px")
            .attr("stroke", "#ADFF2F");
    }
    return (_jsx("div", { children: _jsxs("svg", { ref: svgRef, children: [_jsx("rect", { fill: "#86BDEB", height: height, width: width / 4, x: x_rect1_value, y: y_rect_value }), _jsx("rect", { fill: "#86BDEB", height: height, width: width / 4 - 1, x: x_rect2_value, y: y_rect_value }), _jsx("rect", { fill: "#86BDEB", height: height, width: width / 4 - 1, x: x_rect3_value, y: y_rect_value }), _jsx("rect", { fill: "#86BDEB", height: height, width: width / 4 - 1, x: x_rect4_value, y: y_rect_value })] }) }));
};
export default Linechart;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sankey-component {
    height: 100%;
    width: 100%;
    position: relative;
}

.node-tooltip {
    display: block;
}

.sankey-chart {
    width: 100%;
}

.sankey-tooltip {
    background: white;
    border: 1px solid black;
    border-radius: 4px;
    font-size: medium;
    padding: 5px;
    pointer-events: none;
    position: absolute;
    white-space: pre-wrap;
}
`, "",{"version":3,"sources":["webpack://./src/components/SankeyDiagram/SankeyDiagram.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,uBAAuB;IACvB,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,oBAAoB;IACpB,kBAAkB;IAClB,qBAAqB;AACzB","sourcesContent":[".sankey-component {\n    height: 100%;\n    width: 100%;\n    position: relative;\n}\n\n.node-tooltip {\n    display: block;\n}\n\n.sankey-chart {\n    width: 100%;\n}\n\n.sankey-tooltip {\n    background: white;\n    border: 1px solid black;\n    border-radius: 4px;\n    font-size: medium;\n    padding: 5px;\n    pointer-events: none;\n    position: absolute;\n    white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CatDrawer } from "blocks-react/bedrock/components/Drawer";
import { CatHeading } from "blocks-react/bedrock/components/Heading/Heading";
import React from "react";
import AssetPageDetail from "../../pages/Projects/AssetPageDetail";
import useProjectDetailsStore from "../../store/useProjectDetailsStore";
const AssetDetailDrawer = ({ assetDrawerActive, onAssetSelect, setAssetDrawerActive }) => {
    const { assetDetails } = useProjectDetailsStore((state) => ({
        assetDetails: state.assetDetails,
        projectName: state.projectDetails?.projectName
    }));
    return (_jsx(CatDrawer, { disableBackdropClick: false, disableEscClick: true, hasBackdrop: true, isActive: assetDrawerActive, onBlDrawerClose: () => {
            setAssetDrawerActive(false);
            onAssetSelect(assetDetails);
        }, onBlDrawerOpen: () => { }, preserveContent: false, width: "60%", children: _jsxs(React.Fragment, { "data-test": "asset-details-drawer", children: [_jsx("div", { "data-test": "asset-details-drawer-header", slot: "header", children: _jsx(CatHeading, { "data-test": "asset-details-drawer-title", level: "h1", variant: "title", children: "Asset Details" }) }), _jsx(AssetPageDetail, {})] }, ".0") }));
};
export default AssetDetailDrawer;

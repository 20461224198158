import { jsx as _jsx } from "react/jsx-runtime";
import { NodeToolbar, Position } from "@xyflow/react";
import { useEffect, useRef, useState } from "react";
const NodeToolbarWrapper = (props) => {
    const SEVENTY_FIVE = 75;
    const { align, children, className, isVisible, nodeId, offset, position, ...rest } = props;
    const nodeToolBarRef = useRef(null);
    const [newPosition, setNewPosition] = useState(Position.Left);
    const [newAlign, setNewAlign] = useState("start");
    const adjustToolTipPosition = (tooltip) => {
        const boundingRect = tooltip.getBoundingClientRect();
        const containerRect = tooltip.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.getBoundingClientRect();
        let pos;
        let alignment = "start";
        if (!containerRect) {
            return;
        }
        if ((boundingRect.x > containerRect.width && boundingRect.right < containerRect.right) ||
            boundingRect.right + boundingRect.width > containerRect.right) {
            pos = Position.Left;
        }
        else {
            pos = Position.Right;
        }
        const isCondtionOne = boundingRect.left + boundingRect.width > containerRect.right;
        const isCondtionTwo = containerRect.left + boundingRect.width > boundingRect.left - SEVENTY_FIVE;
        const isTop = boundingRect.y > containerRect.height;
        const isBottom = boundingRect.top < containerRect.top;
        if (isTop && !isCondtionOne && !isCondtionTwo) {
            alignment = "center";
            pos = Position.Top;
        }
        else if (isBottom && !isCondtionOne && !isCondtionTwo) {
            pos = Position.Bottom;
            alignment = "center";
        }
        setNewPosition(pos);
        setNewAlign(alignment);
    };
    useEffect(() => {
        if (nodeToolBarRef.current && isVisible) {
            adjustToolTipPosition(nodeToolBarRef.current);
        }
    }, [isVisible, position, offset, align]);
    return (_jsx("div", { ref: nodeToolBarRef, children: _jsx(NodeToolbar, { align: newAlign, className: className, isVisible: isVisible, nodeId: nodeId, offset: offset, position: newPosition, ...rest, children: children }) }));
};
export default NodeToolbarWrapper;
